import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';

import Swal from 'sweetalert2';

import dtmfaudio from '../images/dtmf.wav'
import promotionalaudio from '../images/promotionalaudio.wav'
import AudioPlayer from './AudioPlayer';







const Audio = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Ivr Settings"
     }, []);

    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const onrefreshfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

      //  const pathb = e.target.files[0];
       // console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);
        console.log("called")

        el.current.value = "";

    }

    const submitaddagent = () =>{



    }

    const clearupload = (e) =>{

       // e.preventDefault();
        setUploadFile(e.target.reset())
       // e.target.reset();
    }

    const onToggleApprove = async(id) =>{

       //id.preventDefault();
        const formData = new FormData()
        formData.append('id', id)

        const response = await fetch(process.env.REACT_APP_IP+"/User/IvrAudioApprove", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        //props.showAlert(" Uploaded Successfully ", "success");

        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()

     }
    
    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)

        const response = await fetch(process.env.REACT_APP_IP+"/User/IvrAudioUpload", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        //props.showAlert(" Uploaded Successfully ", "success");

        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllPosts()
        optionaudio()

        el.current.value = "";
        


        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }


    const deleteivrsetting = async (event) => {
        //event.preventDefault();
        console.log("event>>>>>>>>" + event)
        var formdata = new FormData()
        formdata.append('id', event)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteIvrsetting", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

       // getAllivrsettingPosts()

    }





    // const [postsPerPage] = useState(5);

    
    const [keypress, setKeypress] = useState(1);
    const [group, setGroup] = useState();
    const [keylength, setKeylength] = useState(1);
    

    const [keypressaudio, setKeypressaudio] = useState(0);
    const [welcomeaudio, setWelcomeaudio] = useState(0);
    const [did, setDid] = useState();
    const [offset, setOffset] = useState(1);
    const [ivroffset, setivrOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [ivrsettingposts, setIvrsettingposts] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCountivrsetting, setPageCountivrsetting] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllIvrAudio?pageNumber=${offset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

   

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    const onIvrInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setivrOffset(offset);

    }
    



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handleIvrPageClick = (event) => {
        const selectedPage = event.selected;
        setivrOffset(selectedPage + 1)
    };

    // for source no in option

    const optionfordid = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allDidbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('didselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['did'] + '">' + json[i]['did'] + '</option>';
        }
    }
// for audio in drop down
const optionaudio = async (e) => {
    const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllIvrAudioSelect", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const jsona = await resppe.json()
    console.log(jsona.length)

    var keypressaudio = document.getElementById('keypressaudio');
    keypressaudio.innerHTML = ""+('<option value="0">NA</option>');
    keypressaudio.innerHTML = keypressaudio.innerHTML +('<option value="1">default</option>');
    for (var l = 0; l < jsona.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        keypressaudio.innerHTML = keypressaudio.innerHTML +
        '<option  value="' + jsona[l]['id'] + '">' +jsona[l]['id']+' | '+ jsona[l]['audioname'] + '</option>';
    }


    var welcomeaudiovar = document.getElementById('welcomeaudio');

    welcomeaudiovar.innerHTML = "0"+('<option value="">NA</option>');
    welcomeaudiovar.innerHTML = welcomeaudiovar.innerHTML +('<option value="1">1 | default.wav</option>');

    for (var l = 0; l < jsona.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        welcomeaudiovar.innerHTML = welcomeaudiovar.innerHTML +
            '<option  value="' + jsona[l]['id'] + '">' +jsona[l]['id']+' | '+ jsona[l]['audioname'] + '</option>';
    }




}

//group option

const optionforgroup = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('groupselectmodel');
    elelist.innerHTML = ""+('<option value="">NA</option>');
   

    for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['groupname'] + '</option>';
    }
}

useEffect(() => {
    optionforgroup()
}, [])


useEffect(() => {
    optionaudio()
}, [])
    

    useEffect(() => {
        optionfordid()
    }, [])

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>
                

                <div className='row mt-1'>
                <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ opacity: 0,duration: 1.5  }}
            //   exit={{scale: 1,animate scale: 0}}

               >

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card userpagebg p-3'>

                            <div className='card-header text-white'>
                                <h2>Existing Audio</h2>

                                <div className="table-responsive">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>ID</th>
                                                <th>User Id</th>
                                                <th>Audio Name</th>
                                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                                           <><th>Approve</th>
                                                             <th></th>
                                                           </> 
                                                            : ''}
                                                {/* <th>Approve Status</th> */}
                                                <th>Play</th>
                                                {/* <th>Download</th> */}

                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>default</td>
                                                <td>
                                                <ReactAudioPlayer
                                                                src={ process.env.REACT_APP_REC_LINK+'/User/Audio?folder=1&name=default.wav'}

                                                                controls /> 

                                                </td>
                                                <td> <i className="fa fa-trash mx-2 btn btn-danger " ></i></td>


                                            </tr> */}

                                            <tr>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>dtmf</td>
                                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                                           <><td></td>
                                                             <td></td>
                                                           </> 
                                                            : ''}
                                                
                                                {/* style={{width:"80%"}} */}
                                                <td >
                                                {/* <ReactAudioPlayer
                                                                src={ dtmfaudio} controls='true' />  */}
                                                 <div className='row'>
                                                    <div className="col-md-6">
                                                    <AudioPlayer src={dtmfaudio}/> 
                                                    </div>
                                                    <div className="col-md-6">
                                                        
<a  href={dtmfaudio} download="dtmfaudio.wav">
<i className="fa fa-download fa-2x mx-2" style={{color:"blue"}} aria-hidden="true"></i> </a>
                                                    </div>
                                                      


                                                 </div>

                                                

                                                </td>

                                                {/* <td>

                                                   <audio controls>
                                                    <source src={ process.env.REACT_APP_REC_LINK+'/User/Audio?folder=1&name=dtmf.wav'} type="audio/ogg" />
                                                   
                                                    
                                                    </audio>
                                               </td> */}

                                                {/* <td><a href={ process.env.REACT_APP_REC_LINK+'/User/Audio?folder=1&name=dtmf.wav'} download="dtmf.wav">DownloadButton</a> </td>
                                                */}
                                               
                                                <td> <i className="fa fa-trash mx-2 btn btn-danger " ></i></td>


                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>1</td>
                                                <td>promotionalaudio</td>
                                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                                           <><td></td>
                                                             <td></td>
                                                           </> 
                                                            : ''}
                                                
                                               
                                                <td>
                                                {/* <audio controls
                                                                src={ promotionalaudio}

                                                                 /> 

                                                 <a  href={promotionalaudio} download="promotionalaudio.wav">
                                                 <i className="fa fa-download fa-2x mx-2" style={{color:"blue"}} aria-hidden="true"></i> </a> */}

                                                 <div className='row'>
                                                    <div className="col-md-6">
                                                    <AudioPlayer src={promotionalaudio}/> 
                                                    </div>
                                                    <div className="col-md-6">
                                                        
<a  href={promotionalaudio} download="promotionalaudio.wav">
<i className="fa fa-download fa-2x mx-2" style={{color:"blue"}} aria-hidden="true"></i> </a>
                                                    </div>
                                                      


                                                 </div>

                                                </td>
                                                <td> <i className="fa fa-trash mx-2 btn btn-danger " ></i></td>


                                            </tr>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.userid}</td>
                                                        <td>{page.audioname}</td>
                                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                                        <>
                                                            <td>{page.approve}
                                                           
                                                            
                                                            
                                                            
                                                            </td>
                                                            <td>
                                                                 <label className="toggle-switch">
                                                                <input type="checkbox" checked={page.approve === "Approved"} 
                                                                onChange={() => onToggleApprove(page.id)} 
                                                               

                                                                />
                                                                <span className="switch" />
                                                            </label>
                                                            </td>

                                                           </> : ''}
                                                       
                                                        {/* <td>{page.approve}</td> */}
                                                        <td>
                                                            {/* <ReactAudioPlayer
                                                                src={process.env.REACT_APP_REC_LINK+'/'+page.userid+'/'+page.audioname}

                                                                controls /> */}
                                                               {/* <ReactAudioPlayer
                                                                src={ process.env.REACT_APP_REC_LINK+'/User/Audio?folder='+page.userid+'&name='+page.audioname}

                                                                controls />   */}
                                                                
                                                                <AudioPlayer src={process.env.REACT_APP_REC_LINK+'/IVRAudio/'+page.userid+'/'+page.audioname}/>   

                                                               {/* <ReactAudioPlayer
                                                                src="https://www.computerhope.com/jargon/m/example.mp3"

                                                                controls /> */}

                                                            {/* <ReactAudioPlayer
                                                                src={page.approve}

                                                                controls /> */}

                                             



                                                        </td>
                                                       
                                                        <td> <i className="fa fa-trash mx-2 btn btn-danger " ></i></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onInputChange} />

                                </div>
                            </div>
                        </div>


                    </div>
                    </motion.div>

                </div>


            </>

        )

    }




    
 

    return (


           <>
           
       
        <div className='userpagebg'>
               
                <div className='col-md-12'>

        <div className='row  mx-1 mt-1 p-4'>

           

            {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
            {localStorage.getItem('roles') === 'ROLE_ADMIN' ?"":
            <div className='col-md-3 col-6 text-white p-2'>
                 

                <form className='form-control' onSubmit={uploadaudioonsubmit}>
                    <div className=''>
                                <div className='col-md-12 mb-2'>
                                    <h4>Upload Audio</h4>
                                </div>

                                <div className='col-md-12 mt-2'>
                                    <div className="mt-2">

                                        <input className=" form-control form-control-sm mt-2" ref={el}
                                            id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                                    </div>

                                </div>
                                <div className='row mt-2'>
                                    
                                    <div className='col-md-12'>
                                        <input className="btn btn-primary mt-3 mx-2" type="reset" value="Refresh" onClick={onrefreshfun} />
                                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                    </div>
                                    
                                </div>   
                    

                    </div>
                </form>

            </div>
        }

{localStorage.getItem('roles') === 'ROLE_ADMIN' ?
            <div className="col-md-12 col-12">

                {/* Display all the posts */}
                {posts}

                <div className="row">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12 mb-5 text-black">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={".."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



          

            </div>:
            <div className="col-md-9 col-12">

            {/* Display all the posts */}
            {posts}

            <div className="row">
                <div className="col-md-3 col-12"></div>

                <div className="col-md-6 col-12 mb-5 text-black">


                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={".."}
                        breakClassName={"break-me"}
                        breakLinkClassName={"break-mef"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={offset - 1}

                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}

                    />


                </div>

            </div>



      

        </div>
}


           


        </div>
    
        <div className="col-md-12 col-12">

   

</div>
</div>

</div>




        </>
    )


}


export default Audio
