import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import { MdAudiotrack } from "react-icons/md";
import Swal from 'sweetalert2';

import dtmfaudio from '../images/dtmf.wav'
import promotionalaudio from '../images/promotionalaudio.wav'
import AudioPlayer from './AudioPlayer';







const Ivr = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Ivr Settings"
     }, []);

    const el = useRef();

    const ref = useRef(null)
const openaddivrmodel = () => {
  //optionfordid();
  ref.current.click();
  
}


const[first , setFirst] = useState("NA")
    const[sec , setSec] = useState("NA")
    const[third , setThird] = useState("NA")



 
  const flowsubmit= async (event) => {
    event.preventDefault();
    console.log(">>>>>submit>>>>")
    console.log(">>>>>first>>>>"+first)
    console.log(">>>>>sec>>>>"+sec)
    console.log(">>>>>third>>>>"+third)
    var formdata = new FormData()
    formdata.append('first', first)
    formdata.append('sec', sec)
    formdata.append('third', third)
    const response = await fetch(process.env.REACT_APP_IP+"/User/AddIvrFlow", {
      method: 'POST',
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body:formdata
  });

  const json = await response.json()
  console.log(json);
  if (json.type === "OK") {
      Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Success',
          background: '#e9ecef',
          text: json.msg,
          showConfirmButton: false,
          timer: 2500
      })
  } else {
      Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'error',
          background: '#e9ecef',
          text: json.msg,
          showConfirmButton: false,
          timer: 4500
      })
  }
  getflowposts()

}
    const [uploadFile, setUploadFile] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const onrefreshfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

      //  const pathb = e.target.files[0];
       // console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);
        console.log("called")

        el.current.value = "";

    }

    const submitaddagent = () =>{



    }

    const clearupload = (e) =>{

       // e.preventDefault();
        setUploadFile(e.target.reset())
       // e.target.reset();
    }

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)

        const response = await fetch(process.env.REACT_APP_IP+"/User/IvrAudioUpload", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        //props.showAlert(" Uploaded Successfully ", "success");

        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllPosts()
        optionaudio()

        el.current.value = "";
        


        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }


    const deleteivrsetting = async (event) => {
        //event.preventDefault();
        console.log("event>>>>>>>>" + event)
        var formdata = new FormData()
        formdata.append('id', event)
        const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteIvrsetting", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllivrsettingPosts()

    }





    // const [postsPerPage] = useState(5);

    
    const [keypress, setKeypress] = useState(1);
    const [group, setGroup] = useState();
    const [keylength, setKeylength] = useState(1);
    

    const [keypressaudio, setKeypressaudio] = useState(0);
    const [welcomeaudio, setWelcomeaudio] = useState(0);
    const [did, setDid] = useState();
    const [offset, setOffset] = useState(1);
    const [ivroffset, setivrOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [ivrsettingposts, setIvrsettingposts] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCountivrsetting, setPageCountivrsetting] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllIvrAudio?pageNumber=${offset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const getAllivrsettingPosts = async () => {
        const resa = await axios.get(process.env.REACT_APP_IP+`/User/Allivrsetting?pageNumber=${ivroffset - 1}&&size=5`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const ivrsettingdata = resa.data;
        const postDataivr = getPostDataivrsetting(ivrsettingdata)
        // Using Hooks to set value
        setIvrsettingposts(postDataivr)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCountivrsetting(Math.ceil(ivrsettingdata.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    const onIvrInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setivrOffset(offset);

    }
    



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handleIvrPageClick = (event) => {
        const selectedPage = event.selected;
        setivrOffset(selectedPage + 1)
    };

    // for source no in option

    const optionfordid = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/allDidbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('didselectmodel');
        elelist.innerHTML = ""+('<option value="">NA</option>');


        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['did'] + '">' + json[i]['did'] + '</option>';
        }
    }
// for audio in drop down
const optionaudio = async (e) => {
    const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllIvrAudioSelect", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const jsona = await resppe.json()
    console.log(jsona.length)

    var keypressaudio = document.getElementById('keypressaudio');
    keypressaudio.innerHTML = ""+('<option value="NA">NA</option>');
    keypressaudio.innerHTML = keypressaudio.innerHTML +('<option value="1">default</option>');
    for (var l = 0; l < jsona.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        keypressaudio.innerHTML = keypressaudio.innerHTML +
        '<option  value="' + jsona[l]['id'] + '">' +jsona[l]['id']+' | '+ jsona[l]['audioname'] + '</option>';
    }


    var welcomeaudiovar = document.getElementById('welcomeaudio');

    welcomeaudiovar.innerHTML = "0"+('<option value="">NA</option>');
    welcomeaudiovar.innerHTML = welcomeaudiovar.innerHTML +('<option value="1">1 | default.wav</option>');

    for (var l = 0; l < jsona.length; l++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        welcomeaudiovar.innerHTML = welcomeaudiovar.innerHTML +
            '<option  value="' + jsona[l]['id'] + '">' +jsona[l]['id']+' | '+ jsona[l]['audioname'] + '</option>';
    }




}

//group option

const optionforgroup = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP+"/User/allGroupbyuserid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('groupselectmodel');
    elelist.innerHTML = ""+('<option value="">NA</option>');
   

    for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | '+json[i]['groupname'] + '</option>';
    }
}

useEffect(() => {
    optionforgroup()
}, [])


useEffect(() => {
    optionaudio()
}, [])
    

    useEffect(() => {
        optionfordid()
    }, [])

    useEffect(() => {
        getAllPosts()
    }, [offset])

    useEffect(() => {
        getAllivrsettingPosts()
    }, [ivroffset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>
                

                <div className='row mt-1'>
               

                </div>


            </>

        )

    }

    const [flowposts, setflowposts] = useState([]);
    const getflowposts = async () => {
      const res = await axios.get(process.env.REACT_APP_IP+`/User/Allivrflow`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
      const fdata = res.data;
      const postData = getglowpostdata(fdata)
      // Using Hooks to set value
      setflowposts(postData)
      // setPageCount(Math.ceil(data.length / postsPerPage))
      //setPageCount(Math.ceil(data.totalPages))
    }
    useEffect(() => {
        getflowposts()
    }, [])
    
    const getglowpostdata = (fdata) => {
      return (
          <>
            
    
            
              <div className='row '>
    
                  {/* <AddCampaignform /> */}
    
    
                  < div className='col-md-12 col-12'>
    
                      <div className='card p-2 userpagebg'>
    
    
    
                          <div className='card-header text-white'>
                              <h4 className='text-center'>Existing IVR Flow</h4>
    
                              <div className="table-responsive mt-3">
    
                                  <table className="table table-striped table-bordered">
                                      <thead className="thead-light ">
                                          <tr>
                                              <th>ID</th>
                                              
                                              <th>Date</th>
                                              
                                              <th>First</th>
                                              <th>Sec</th>
                                              <th>Third</th>
                                             
    
    
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {fdata.map(page => {
                                              return (
                                                  <tr key={page.id}>
                                                      <td>{page.id}</td>
                                                      <td>{page.adddate}</td>
                                                      <td>{page.first}</td>
                                                      <td>{page.sec}</td>
                                                      <td>{page.third}</td>
    
    
                                                  </tr>
                                              );
                                          })}
                                      </tbody>
                                  </table>
                                 
    
                              </div>
                          </div>
                      </div>
    
    
                  </div>
    
              </div>
    
             
          </>
    
      )
    
    }

    const getPostDataivrsetting = (ivrsettingdata) => {
        return (
            <>
                

                <div className=''>
                <motion.div   
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ opacity: 0,duration: 1.5  }}
            //   exit={{scale: 1,animate scale: 0}}

               >

                    {/* <AddCampaignform /> */}


                    
                    <h2>IVR Settings</h2>
                    {/* <h2 className='mt-1 mb-1'>IVR Settings</h2> */}
      <button className='btn btn-primary  btn-sm  mx-1 mb-1 ' onClick={openaddivrmodel}>
                            <MdAudiotrack size="20" className='mx-1'/>Add IVR</button>

                            <NavLink className="btn btn-success  btn-sm  mx-1 mb-1" to="/Audio">Upload Audio</NavLink>
      
      <NavLink className="btn btn-info  btn-sm  mx-1 mb-1" to="/Group">Group</NavLink>
      <NavLink className="btn btn-secondary  btn-sm  mx-1 mb-1" to="/Agent">AssignAgents</NavLink>

                        

                            <div className='card text-black'>
                                   <div className='mycard-header text-center'>
                                    {/* <h2>Existing IVR</h2> */}

                                    <h4 className='text-center'>Existing IVR</h4>

                                    </div>

                                <div className=" card-body userpagebg">
                                    <div className=''>

                                <table class="table table-striped table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>ID</th>
                                                <th>Did</th>
                                                <th>Plain-Audio</th>
                                                <th></th>
                                                <th>DTMF-Audio</th>
                                                <th>Name</th>
                                                {/* <th>Keypress Length</th> */}
                                                <th>DTMF</th>
                                                <th>Group</th>
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ivrsettingdata.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>{page.did}</td>
                                                        <td>
                                                            {/* <ReactAudioPlayer
                                                                src={'http://180.179.210.24:8080/IvrAudioFile/'+page.userid+'/'+page.welcomeaudio}

                                                                controls /> */}

                                                            {/* <ReactAudioPlayer
                                                                src={ process.env.REACT_APP_REC_LINK+'/User/Audio?folder='+page.userid+'&name='+page.welcomeaudio}

                                                                controls />  */}

                                                    <AudioPlayer src={process.env.REACT_APP_REC_LINK+'/IVRAudio/'+page.userid+'/'+page.welcomeaudio}/>  


                                                        </td>
                                                        <td>{page.welcomeaudio}</td>
                                                        <td>
                                                            {/* <ReactAudioPlayer
                                                                src={'http://180.179.210.24:8080/IvrAudioFile/'+page.userid+'/'+page.keypressaudio}

                                                                controls /> */}

                                                                {/* <ReactAudioPlayer
                                                                src={ process.env.REACT_APP_REC_LINK+'/User/Audio?folder='+page.userid+'&name='+page.keypressaudio}

                                                                controls />  */}

                                                          <AudioPlayer src={process.env.REACT_APP_REC_LINK+'/IVRAudio/'+page.userid+'/'+page.keypressaudio}/>      


                                                        </td>
                                                        <td>{page.keypressaudio}</td>
                                                        {/* <td>{page.keylength}</td> */}
                                                        <td>{page.inputkey}</td>
                                                        <td>{page.groupname}</td>


                                                        <td> <i className="fa fa-trash mx-2 btn btn-danger " onClick={() => deleteivrsetting(page.id)} ></i></td>




                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage" onChange={onIvrInputChange} />
                                 </div>
                                
                            </div>
                        </div>


                    
                    </motion.div>

                </div>


            </>

        )

    }



   
    
    const submitivrsetting = async(e) => {
        e.preventDefault();
        if(keylength==='undefined')
        {
            keylength=1;

        }
        console.log(">>>>>>>did>>>>>>>>"+did)
        console.log(">>>>>>>>welcomeaudio>>>>>>>"+welcomeaudio)
        console.log(">>>>>>>>keypressaudio>>>>>>>"+keypressaudio)
        console.log(">>>>>>>>>keylength>>>>>>"+keylength)
        console.log(">>>>>>>>>group>>>>>>"+group)
        console.log(">>>>>>>>>keypress>>>>>>"+keypress)
        const formData = new FormData()
        formData.append('did', did)
        formData.append('welcomeaudioid', welcomeaudio)
        formData.append('keypressaudioid', keypressaudio)
        formData.append('keylength', keylength)
        formData.append('keypress', keypress)
        formData.append('groupid', group)

        const response = await fetch(process.env.REACT_APP_IP+"/User/AddIvrsetting", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        //props.showAlert(" Uploaded Successfully ", "success");

        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 4500
            })
        }
        ref.current.click();
       getAllivrsettingPosts()
       optionaudio()
       optionfordid()
       optionforgroup()
       setWelcomeaudio(0)
       setKeypressaudio(0)
     
    }
    
 

    return (


           <>
           

           <div className='userpagebg'>


                {/* flow */}
                <h2>IVR Flow Order</h2>
      <form onSubmit={flowsubmit}>
          
           <div className="row">
             <div className="col-md-3">
                   <div className="row">
                          <div className="col-sm-2">
                              <label>1.</label>
                          </div>
                          <div className="col-md-10">
                          <select className='form-control' value={first} onChange={(e) => setFirst(e.target.value)}>
                               <option value="">NA</option>
                                <option value="TimeCheck">TimeCheck</option>
                                <option value="MissedCall">MissedCall</option>
                                <option value="IVR">IVR</option>
                            
                          </select>
                          </div>
                    </div>
                    <div className="row mt-1">
                          <div className="col-sm-2">
                              <label>2.</label>
                          </div>
                          <div className="col-md-10">
                          <select className='form-control' value={sec} onChange={(e) => setSec(e.target.value)}>
                               <option value="">NA</option>
                                <option value="TimeCheck">TimeCheck</option>
                                <option value="MissedCall">MissedCall</option>
                                <option value="IVR">IVR</option>
                            
                          </select>
                          </div>
                    </div>
                    <div className="row mt-1">
                          <div className="col-sm-2">
                              <label>3.</label>
                          </div>
                          <div className="col-md-10">
                          <select className='form-control' value={third} onChange={(e) => setThird(e.target.value)}>
                               <option value="">NA</option>
                                <option value="TimeCheck">TimeCheck</option>
                                <option value="MissedCall">MissedCall</option>
                                <option value="IVR">IVR</option>
                            
                          </select>
                          </div>
                    </div>
                   
                   <button className="btn btn-primary btn-sm float-end mt-1 mb-1" type="submit">Submit</button>
              
             </div>
             <div className="col-md-9">
                    
             {flowposts}
             </div>
            
             
           </div>

             
         
      </form>






       
            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Add Ivr
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add IVR</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body userpagebg">

                            <div className='col-md-12 col-12 text-black'>
                                <div className='mt-1'>

                                    <form className=" text-black" onSubmit={submitivrsetting}>
                                        <div className='card  userpagebg p-3'>
                                            




                                            <div className=" mb-3 ">
                                                <b htmlFor="groupname">Plain-Audio:</b>
                                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                                <select id="welcomeaudio" className="form-select" onChange={(e) => setWelcomeaudio(e.target.value)}
                                                >
                                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                                </select>

                                            </div>
                                            <div className=" mb-3 ">
                                                <b htmlFor="groupname">Dtmf-Audio:</b>
                                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                                <select id="keypressaudio" className="form-select" onChange={(e) => setKeypressaudio(e.target.value)}
                                                >
                                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                                </select>

                                            </div>
                                            {/* <div className=" mb-3 ">
                                <b htmlFor="groupname">Keypress Length:</b> */}
                                            {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                            {/* <select id="keylength" className="form-select" onChange={(e) => setKeylength(e.target.value)}
                                    >
                                        <option value="1">NA</option>
                                     <option value="1">1</option>
                                     <option value="2">2</option> 
                                     <option value="3">3</option> 
                                     <option value="4">4</option> 
                                     <option value="5">5</option>
                                     <option value="6">6</option> 
                                     <option value="7">7</option> 
                                     <option value="8">8</option> 
                                     <option value="9">9</option> 

                                </select>

                            </div> */}
                                            <div className=" mb-3 ">
                                                <b htmlFor="keypress">Dtmf :</b>
                                                <input type="text" className='form-control' onChange={(e) => setKeypress(e.target.value)} />

                                            </div>
                                            <div className=" mb-3 ">
                                                <b htmlFor="groupname">Select Group|Department:</b>
                                                {/* <label htmlFor="user" className="form-label">User Name</label> */}
                                                <select id="groupselectmodel" className="form-select" onChange={(e) => setGroup(e.target.value)}
                                                >
                                                    {/* <option value={euserid}>{euserid} | {eusername}</option>
                            <option value=""></option> */}


                                                </select>

                                            </div>
                                                <div className="d-grid gap-2">
                                                <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                                </div>
                                        </div>

                                        
                                    </form>
                                </div>


                            </div>




                        </div>
                    </div>
                </div>
            </div>
            </div>

            {/* form close */}
           

            <div className="col-md-12 col-12">

                {/* Display all the posts */}
                {posts}


            </div>

          



           


       
        <div className="col-md-12 col-12">

{/* Display all the posts */}
{ivrsettingposts}

<div className="row">
    <div className="col-md-3 col-12"></div>

    <div className="col-md-6 col-12 mb-5 text-black">


        <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={".."}
            breakClassName={"break-me"}
            breakLinkClassName={"break-mef"}
            pageCount={pageCountivrsetting}
            onPageChange={handleIvrPageClick}
            forcePage={offset - 1}

            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}

        />


    </div>

</div>

</div>





        </>
    )


}


export default Ivr
