import React, { useState, useRef } from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { AiOutlinePause } from 'react-icons/ai';

const AudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const styles = {
    container: {
      width: 50,
      margin: '5px auto',
    },
    audio: {
      width: '5px',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 5,
    },
    playPauseBtn: {
      width: 30,
      height: 20,
      border: '1px solid #ccc',
      borderRadius: 50,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
    },
    progressBar: {
      flexGrow: 1,
      height: 5,
      backgroundColor: '#ccc',
      position: 'relative',
      borderRadius: 5,
      marginRight: 20,
    },
    progress: {
      height: '100%',
      backgroundColor: '#333',
      borderRadius: 5,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(!isPlaying)
      audioRef.current.pause();
    } else {
      setIsPlaying(!isPlaying)
      audioRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleOnEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div style={styles.container}>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleOnEnded}
        style={styles.audio}
      />
      <div style={styles.controls}>
        <button onClick={handlePlayPause} style={styles.playPauseBtn}>
          {isPlaying ? <AiOutlinePause size="30" />: <AiOutlinePlayCircle size="30" />}
        </button>
        <div style={styles.progressBar}>
          <div
            style={{
              ...styles.progress,
              width: `${(currentTime / duration) * 100}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};



export default AudioPlayer;
