import React from 'react'
import { useEffect, useState} from 'react'
import axios from 'axios'



    
    export const LiveAgentRecord =  (offset) =>
     {
        //console.log("API CAlled"+offset)
           // const [offset, setOffset] = useState(1); 
           return axios.get(process.env.REACT_APP_IP+`/User/getLiveAgentRecord?pageNumber=${offset - 1}&&size=20`,
            { 
            headers: { 
                "Authorization": `Bearer ${localStorage.getItem('token')}`
                     }
           })

           // return ress;

    }
    export const AgentLiveAgentRecord=  (offsetagent) =>
    {
       //console.log("API CAlled"+offset)
          // const [offset, setOffset] = useState(1); 
          return axios.get(process.env.REACT_APP_IP+`/User/getAgentLiveAgentRecord?pageNumber=${offsetagent - 1}&&size=20`,
           { 
           headers: { 
               "Authorization": `Bearer ${localStorage.getItem('token')}`
                    }
          })

          // return ress;

   }


