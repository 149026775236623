import React from 'react'

import { useState,useEffect } from 'react'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';
import axios from 'axios'

const AllowIP = () => {

   // const [disp , setDisp] = useState("")
    const[userbreak,setUserbreak] = useState("")
    const [posts, setAllPosts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)

    //const AddBreakFun = ()
    const AddBreakFun = async (e) => {
        e.preventDefault();
        //alert(">>>>"+camp+">>>>"+disp)
        //console.log("id>>>>>>>>" + e)
           
        var formdata = new FormData()
       // formdata.append('campid', camp)
        formdata.append('ip', userbreak)
        
        const response = await fetch(process.env.REACT_APP_IP+"/User/AllowIP", {
            method: 'POST',     
            headers: {
                //  'Content-Type': 'application/json',
        
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        setUserbreak("")
        
        // setGroupname("NA")
        //  set
        
        //getAllPosts()
        //setCamp("")
     //   setDisp("")


    }

 


const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

}
const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
}


  return (
    <>
    <div className='row'>
             <div className='col-md-3'>
                </div>

            <div className='col-md-6 col-12 mt-2 p-5'>
                    <form className=' card mt-1 ' style={{minHeight: "100px"}} 
                        onSubmit={AddBreakFun}
                    >
                    <div className='card-header text-black text-center'>
                    <h4>Allow IP</h4>
                    </div>
                    <div className='car-body p-4'>
                       
                    <div className=" mt-2 mb-2 ">
                    <label htmlFor="dispo">EnterIP</label>
                    <input type="text" className="form-control" id="dispo" placeholder="EnterIP"
                    name="dispo" 
                        value={userbreak}
                        onChange={(e) => setUserbreak(e.target.value)} 
                    required />
                    <input className=" form-control text-white btn btn-primary mt-3" type="submit" value="Submit" />

                    </div>
                    </div>

                    </form>
            </div>
           
</div>
    
    </>
  )
}

export default AllowIP