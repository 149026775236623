import React, { useContext } from 'react'
import { FormContext } from '../../FormContext';
const Checkbox = ({ fieldid, fieldlabel, fieldvalue }) => {
    const { handleChange } = useContext(FormContext)

    return (
        <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={fieldvalue}
                onChange={event => handleChange(fieldid, event)}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">{fieldlabel}</label>
        </div>
    )
}

export default Checkbox
