import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';


import axios from 'axios'

// import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from './AudioPlayer';
import { AnimatePresence, motion } from "framer-motion";

// import Userivrnavbar from './Userivrnavbar';
import { CSVLink, CSVDownload } from "react-csv";


const Report = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "All CallReport"
 }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 1.0
  };
  
  const pageStyle = {
    // position: "absolute"
  };

  


  const headers = [
    { label: "Caller", key: "fone" },
    { label: "Did", key: "did" },
    { label: "Calltype", key: "type" },
    { label: "Agents Name", key: "agentid" },
    { label: "Agents Sip", key: "agentsip" },
    { label: "Start", key: "dialtime" },
    { label: "End", key: "customerhanguptime" },
    { label: "Duration", key: "totalduration" },
    { label: "Keypress", key: "pressedkey" },
    { label: "Dial Status", key: "dialstatus" }


  ];

 
  
  //const data = reportdaat;

  //downloadcsv
  const downloadcsv = async () => {
    const res = await axios.get(process.env.REACT_APP_IP + `/User/DpwnloadSampleUpload/CampaignReport.csv`,
      { 
        headers: 
        { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem('token')}` 
        } 
      }
      )

  }

  const openInNewTab = url => {

    var url=process.env.REACT_APP_IP + `/User/DpwnloadSampleUpload/CampaignReport.csv`
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  

 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);

  const [reportdaat, setreportdaat] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
     
       
        <div className='mybgcolor '>
       
          <div className=" table-responsive p-4">
          <motion.div
    // style={pageStyle}
    // initial={{ x: 300, opacity: 0 }}
    // animate={{ x: 0, opacity: 1,rotate: 360}}
    // exit={{ rotate: 360}}
    // variants={pageVariants}
    // transition={pageTransition}
    // initial={{ scale: 0 }}
    // // animate={{ scale: 1 }}
    // animate={{
    //   scale: [1, 2, 2, 1, 1],
    //   rotate: [0, 0, 270, 270, 0],
    //   borderRadius: ["20%", "20%", "50%", "50%", "20%"],
    // }}
    // transition={{ duration: 1.5 }}


      //animate={{ rotate: [0,1,1, 0] }}
               // transition={{ type: "spring", duration: 5, bounce: 0.6 }}
              //  initial={{ x: 180 }}

               initial={{ rotate: 270 }}

              //  animate={{ x: 0 }}
               animate={{ x: 0, opacity: 2.0,rotate: 360}}
               transition={{ duration: 0.5}}
              // transition={{ type: "spring",bounce: 0.55,duration: 1.1}}

              // transition={{ type: "spring", duration: 5, bounce: 0.6 }}

  >
            <table className="table table-hover table-bordered checkoddata ignoreline">
              <thead className="bg-darkblue text-white">
                <tr>
                  <th>ID</th>
                  {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                <th>UserId</th>:""}
                  <th>Caller</th>
                  <th>Did</th>
                  <th>Calltype</th>
                  <th>Agents</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Duration</th>
                  <th>Dispo</th>
                  <th>Keypress</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Recording</th>
                 
                 
                  

                </tr>
              </thead>
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      <td>{page.id}</td>
                      {localStorage.getItem('roles') === 'ROLE_SUPERADMIN' ?
                                                <th>{page.userid}</th>:""}
                      <td>{page.fone}</td>
                      <td>{page.did}</td>
                      <td>{page.type}</td>
                      <td>{page.agentid} | {page.agentsip}</td>
                      <td >{page.dialtime}</td>
                      <td>{page.customerhanguptime}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.dispoone}</td>
                      <td>{page.pressedkey}</td>
                      <td>{page.dialstatus}</td>
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                     
                      {/* <td><i className="fa fa-phone"  ></i></td>  */}
                      <td className='text-center'>
                      <button className=" btn btn-success btn-mysm" >
                                                                <i className="fa fa-phone" ></i>
                                                            </button>
                                                            </td>
                      
                      {/* <td className='bg-secondary'> */}
                      <td className='text-center'>
                          {/* <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname}
                              controls /> */}

                             <AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.userid+'/'+page.fold+'/'+page.recname} />
                      </td>

                  
                      {/* <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td> */}


                    </tr>
                  );
                })}
              </tbody>
            </table>

            Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />

</motion.div>
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
         
        </div>
       
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllReport?pageNumber=${offset - 1}&&
    size=10`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts();
  }, [offset])



  return (
  <>
  
 
    <div className='row'>

    <ul className="nav nav-tabs">
  <li className="nav-item ms-2">
      <NavLink className="nav-link mybg-dark text-black ms-1"  to="/Report"><b>All Report</b></NavLink>
  </li>
  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/FilterReport"><b>Filter Report</b></NavLink>
  </li>

  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/Agentlog"><b>Agent Log</b></NavLink>
  </li>
  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/AgentPerformance"><b>AgentPerformance</b></NavLink>
  </li>
  
</ul>
       
        <div className='row'>
          <div className='col-md-12'>
 
            <div className="table-responsive">
              {/* <div className='card'>
                  <div className='card-header'>
                  
                    </div>

              </div> */}
              <h1 className='text-center card-header'>Report</h1>


              {/* Display all the posts */}
              {posts}

              {/* Using React Paginate */}
             

            </div>
            <div className='row mb-5'>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <ReactPaginate
                     nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg"></i>}
                     previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg"></i>}
                     
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}
                
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination2"}
                    activeClassName={"active"} />

              </div>
              <div className='col-md-4'></div>
            </div>


          </div>


        </div>
       
      </div>

      </>
    
  )
}

export default Report
