import ReactModal from 'react-modal';

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel="Modal"
    >
      {children}
      
    </ReactModal>
  );
};

export default Modal;
