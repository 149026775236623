import React from 'react'
import Checkbox from './elements/Checkbox';
import Input from './elements/Input';
import Select from './elements/Select';
const Element = ({ field: { fieldtype, fieldid, fieldlabel, fieldplaceholder, fieldvalue, fieldoptions } }) => {

    switch (fieldtype) {
        case 'TEXT':
            return (<Input
                fieldid={fieldid}
                fieldlabel={fieldlabel}
                fieldplaceholder={fieldplaceholder}
                fieldvalue={fieldvalue}
                readonly={false}

            />)
            case 'Label':
                return (<Input
                    fieldid={fieldid}
                    fieldlabel={fieldlabel}
                    fieldplaceholder={fieldplaceholder}
                    fieldvalue={fieldvalue}
                    readonly={true}
    
                />)
        case 'DROPDOWN':
            return (<Select
                fieldid={fieldid}
                fieldlabel={fieldlabel}
                fieldplaceholder={fieldplaceholder}
                fieldvalue={fieldvalue}
                fieldoptions={fieldoptions}
            />)
        case 'checkbox':
            return (<Checkbox
                fieldid={fieldid}
                fieldlabel={fieldlabel}
                fieldvalue={fieldvalue}
            />)

        default:
            return null;
    }


}

export default Element
