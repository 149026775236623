import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';

const ChurnLeads = () => {



    const [campid, setCampid] = useState(0)
    const [dispo, setDispo] = useState("NA")
    const [posts, setAllPosts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)

    const [afternoofsec, setAfternoofsec] = useState(150)
    const [maxtry, setMaxtry] = useState(2)


    


    //   useEffect(() => {
    //     optionforDispo()
    //   }, [campid])
    // const handleChange = (event) => {
    //     setValue(event.target.value);
    //   };


      const setCampfun  =  (e) => {
       // alert(e)

      //var abc=e;

         setCampid(e);

        optionforDispo(e)

      }


    //All Campaign option

    const optionforcamp = async (e) => {
        const respp = await fetch(process.env.REACT_APP_IP + "/User/allGroupbyuserid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)

        var elelist = document.getElementById('campid');
        elelist.innerHTML = "" + ('<option value="NA">NA</option>');

        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['id'] + '">' + json[i]['id'] + ' | ' + json[i]['campname'] + '</option>';
        }
    }

    useEffect(() => {
        optionforcamp()
    }, [])


    const optionforDispo = async (e) => {
       // e.preventDefault();
        //alert(">>>>>>campidd>>>>"+e)
       // let iyear = parseInt(e);
        var formdata = new FormData()
        formdata.append('campid', e)
        const respp = await fetch(process.env.REACT_APP_IP+"/User/AllDispoListBycampid", {
            
            method: 'POST',
            headers: {
               // 'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
      
        var elelist = document.getElementById('dispid');
        elelist.innerHTML = ""+('<option value="NA">Select Dispo</option>');
       
      
        for (var i = 0; i < json.length; i++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            elelist.innerHTML = elelist.innerHTML +
                '<option  value="' + json[i]['dispo'] + '">' + json[i]['dispo'] +  '</option>';
        }

        elelist.innerHTML = elelist.innerHTML +('<option value="NOANSWER">NOANSWER</option>');
        elelist.innerHTML = elelist.innerHTML +('<option value="CANCEL">CANCEL</option>');
        elelist.innerHTML = elelist.innerHTML +('<option value="CHANUNAVAIL">CHANUNAVAIL</option>');
        

       
      }


    const AddLeadsFun = async (e) => {
        e.preventDefault();
        //alert(">>>>"+camp+">>>>"+disp)
        //console.log("id>>>>>>>>" + e)

        var formdata = new FormData()
        formdata.append('campid', campid)
        formdata.append('dispo', dispo)
        formdata.append('callsaftersec', afternoofsec)
        formdata.append('retry', maxtry)
       

        const response = await fetch(process.env.REACT_APP_IP + "/User/AddChurnLeads", {
            method: 'POST',
            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }


        // setGroupname("NA")
        //  set

        getAllPosts()
        //setCamp("")
        setDispo("")

    }

    //getAllPosts
    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/AllChurns?pageNumber=${offset - 1}&&size=6`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }
    useEffect(() => {
        getAllPosts()
    }, [offset])


    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card mt-1' >



                            <div className=''>
                                <div className='card-header text-black'>
                                    <h4 className='text-center'>Existing Lead Churn</h4>
                                </div>

                                <div className="table-responsive p-1">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>S.N</th>

                                                <th>Campaign</th>

                                                <th>Disposition</th>
                                                <th>After Sec</th>
                                                <th>Retry no</th>

                                                <th>Date Added</th>

                                                <th>Status</th>
                                                <th>Action</th>
                                                {/* <th>Delete</th> */}


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>

                                                        <td>{page.campid} | {page.campaign}</td>

                                                        <td>{page.dispo}</td>
                                                        <td>{page.callsaftersec}</td>
                                                        <td>{page.retry}</td>

                                                        <td>{page.createdate}</td>
                                                        <td>
                                                                   <label className="toggle-switch">
                                                                        <input type="checkbox" checked={page.status === "START"} 
                                                                            onChange={() => onToggle(page.id)} 
                                                                        />
                                                                        <span className="switch" />
                                                                    </label>


                                                        </td>

                                                        <td className='text-center'>
                                                            {/* <button className=" mx-2 btn btn-primary disabled" >
                                                            <i className="fa fa-edit" ></i>
                                                        </button> */}
                                                            <button className='btn btn-danger btn-sm '>

                                                                <i className="fa fa-trash "
                                                                    onClick={() => deleteDispo(page.id)}

                                                                ></i>

                                                            </button>


                                                        </td>





                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage"
                                        onChange={onInputChange}
                                    />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }

    const deleteDispo = async (e) => {
        // e.preventDefault();
        //refaddgroupagent.current.click();
        console.log("id>>>>>>>>" + e)

        var formdata = new FormData()
        formdata.append('id', e)

        const response = await fetch(process.env.REACT_APP_IP + "/User/deleteChurn", {
            method: 'POST',
            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        const id = e
        // alert(id)
        const response = await fetch(process.env.REACT_APP_IP+"/User/StartStopRechurn", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": id

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        //props.showAlert("Done Successfully ", "success");
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()
        setIsToggled(!isToggled);


    }



    return (
        <>


            <div className='row'>

                <div className='col-md-3'>
                    <form className=' card mt-1 ' style={{ minHeight: "100px" }}
                        onSubmit={AddLeadsFun}
                    >
                        <div className='card-header text-black'>
                            <h4>Churn Leads</h4>
                        </div>
                        <div className='car-body'>
                            <div className=" mt-2">
                                <label htmlFor="sel1" className="form-label">Select Campaign:</label>
                                <select className="form-select" name="campaign_mode" id="campid"
                                   // onChange={(e) => setCamp(e.target.value)}
                                 value={campid}
                                // onChange={(e) => setCampid(e.target.value)}
                               //  onSelect={setCampfun}
                               //{(e) => setCampfun(e.target.value)}
                                onChange={(e) => setCampfun(e.target.value)}
                                >


                                </select>

                            </div>

                            <div className=" mt-2">
                                <label htmlFor="sel1" className="form-label">Select Disposition:</label>
                                <select className="form-select" name="disp" id="dispid"


                                // onChange={(e) => setCamp(e.target.value)}
                                 value={dispo}
                                 onChange={(e) => setDispo(e.target.value)}
                                >
                                    {/* <option value="NOANSWER">NOANSWER</option>
                                    <option value="CANCEL">CANCEL</option> */}


                                </select>

                            </div>


                            <div className=" mt-2 mb-2 ">
                                <label htmlFor="dispo">After No Of Sec</label>
                                <input type="number" className="form-control" id="dispo" placeholder="No Of Sec"
                                    name="afternoofsec"
                                   value={afternoofsec}
                                    onChange={(e) => setAfternoofsec(e.target.value)}
                                    required />
                               

                            </div>

                            <div className=" mt-2 mb-2 ">
                                <label htmlFor="dispo">Max No Retry</label>
                                <input type="number" className="form-control" id="maxtryid" placeholder="max retry"
                                    name="maxtry"
                                    value={maxtry}
                                    onChange={(e) => setMaxtry(e.target.value)}
                                    required />                              
                            </div>

                            <div className=" mt-2 mb-2 ">
                                
                                <input className=" form-control text-white btn btn-primary mt-3" type="submit" value="Submit" />

                            </div>
                        </div>

                    </form>
                </div>
                <div className='col-md-9'>

                    {posts}
                    <div className="row mb-5">
                        <div className="col-md-3 col-12">

                        </div>

                        <div className="col-md-6 col-12">
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />
                        </div>

                    </div>

                </div>

            </div>




        </>
    )
}

export default ChurnLeads