import React, { useContext } from 'react'
import { FormContext } from '../../FormContext';

const Input = ({ fieldid, fieldlabel, fieldplaceholder, fieldvalue ,readonly }) => {
    const { handleChange } = useContext(FormContext)
    return (
        <div className="col-md-6 col-12 mb-3 ">
            <label htmlFor="exampleInputEmail1" className="form-label" style={{textTransform: 'capitalize'}}>{fieldlabel}</label>
            <input type="text" className="form-control"  id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder={fieldplaceholder ? fieldplaceholder : ''}
                value={fieldvalue}
                onChange={event => handleChange(fieldid, event)}
                readOnly={readonly}
            />
            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
        </div>
    )
}

export default Input
