import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import AudioPlayer from './AudioPlayer';

import axios from 'axios'

import ReactAudioPlayer from 'react-audio-player';
import { AnimatePresence, motion } from "framer-motion";


import { CSVLink, CSVDownload } from "react-csv";
import Swal from 'sweetalert2';



const Calllog = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])

  useEffect(() => {
    document.title = "Ivr Calllog"
 }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 1
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.2
    }
  };
  
  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 1.0
  };
  
  const pageStyle = {
    // position: "absolute"
  };

  
  const clicktocalfromreport = async(e) => {
    //e.preventDefault();
    //refclicktocall.current.click();
    //console.log(">>>>>>>>>>>>>>>>agentid>>>>>>>>>>>>>>>"+agentid)
    console.log(">>>>>>>>>>>>>>>>number>>>>>>>>>>>>>>>>"+e)
    var formdata = new FormData()
    // formdata.append('agentid', agentid)
    formdata.append('number', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/Clicktocallivr", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //  'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }

        //setAgentid("")
       // setNumber("")


    
   }

  const headers = [
    { label: "Caller", key: "CustomerNumber" },
    { label: "Did", key: "did" },
    { label: "Calltype", key: "type" },
    { label: "Agents Name", key: "agentname" },
    { label: "Agents number", key: "AgentNumber" },
    { label: "Start", key: "dialtime" },
    { label: "End", key: "agenthanguptime" },
    { label: "Duration", key: "totalduration" },
    { label: "Keypress", key: "pressedkey" },
    { label: "Dial Status", key: "dialstatus" }


  ];

  const getdata = async () => {
    const res = await axios.get(process.env.REACT_APP_IP + `/User/AllReportdownload`,
      { 
        headers: 
        { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem('token')}` 
        } 
      }
      )

      // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_REC_LINK)
      // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_IP)
      

    //const datakk = res.data;

   // const json = await res.data

    //alert(JSON.stringify(res.data))

    //return JSON.stringify(res.data);
    setreportdaat(res.data)


  }
  
  //const data = reportdaat;

  useEffect(() => {
    getdata();
  }, [])
  
 
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);

  const [reportdaat, setreportdaat] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
     
       
        <div className='userpagebg '>
       
          <div className=" table-responsive p-2">
          <motion.div
    // style={pageStyle}
    // initial={{ x: 300, opacity: 0 }}
    // animate={{ x: 0, opacity: 1,rotate: 360}}
    // exit={{ rotate: 360}}
    // variants={pageVariants}
    // transition={pageTransition}
    // initial={{ scale: 0 }}
    // // animate={{ scale: 1 }}
    // animate={{
    //   scale: [1, 2, 2, 1, 1],
    //   rotate: [0, 0, 270, 270, 0],
    //   borderRadius: ["20%", "20%", "50%", "50%", "20%"],
    // }}
    // transition={{ duration: 1.5 }}


      //animate={{ rotate: [0,1,1, 0] }}
               // transition={{ type: "spring", duration: 5, bounce: 0.6 }}
               initial={{ x: -1200 }}

               animate={{ x: 0 }}
               transition={{ duration: 0.5}}
              // transition={{ type: "spring",bounce: 0.55,duration: 1.1}}

              // transition={{ type: "spring", duration: 5, bounce: 0.6 }}

  >
            <table className="table table-hover table-bordered ">
              <thead className="bg-darkblue text-white">
                <tr>
                  <th>ID</th>

                  <th>Caller</th>
                  {/* <th>CALL</th>
                  <th>Wa</th> */}
                  <th>Recording</th>
                  {/* <th>Did</th> */}
                  <th>Calltype</th>
                  <th>Agents</th>
                  <th>Start</th>
                  {/* <th>End</th> */}
                  <th>Duration</th>
                  {/* <th>Keypress</th>
                  <th>Status</th> */}
                 
                  {/* <th>Circle</th>
                  <th>Operator</th>
                  <th>Remarks</th>
                  <th>SMS Suite</th> */}
                  

                </tr>
              </thead>
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      <td>{page.id}</td>
                      <td>{page.CustomerNumber}</td>
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={(e) => clicktocalfromreport(page.CustomerNumber)} ></i></td> 
                      <td><a style={{color:'white'}}className="fa fa-whatsapp fa-lg btn btn-success" href={'https://web.whatsapp.com/send?phone='+91+page.CustomerNumber+'&text=Hii&source=&data=&app_absent=0'} target="_blank"></a></td> */}

                      <td>
                          {/* <ReactAudioPlayer 
                                    src={process.env.REACT_APP_REC_LINK+'/'+page.fold+'/'+page.recname}
                              controls /> */}

                                                            {/* <ReactAudioPlayer
                                                                src={ process.env.REACT_APP_REC_LINK+'/User/IvrRecordingAudio?folder='+page.fold+'&name='+page.recname}

                                                                controls />  */}

                                      <AudioPlayer src={process.env.REACT_APP_REC_LINK+'/IVRREC/'+page.fold+'/'+page.recname}/> 

                      </td>
                      {/* <td>{page.did}</td> */}

                      
                      <td>{page.type}</td>
                      <td>{page.agentname}  {page.AgentNumber}</td>
                      <td >{page.dialtime}</td>
                      {/* <td>{page.agenthanguptime}</td> */}
                      <td>{page.totalduration}</td>


                      
                      {/* <td>{page.pressedkey}</td>
                      <td>{page.dialstatus}</td> */}
                      {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}
                     
                    

                      {/* <td></td>
                      <td></td>
                      <td><i className="fa fa-comment-o fa-xl btn btn-success " style={{marginLeft:"15px"}}  ></i></td>
                      <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td> */}


                    </tr>
                  );
                })}
              </tbody>
            </table>

            Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />

</motion.div>
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
         
        </div>
       
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AllReport?pageNumber=${offset - 1}&&
    size=10`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts();
  }, [offset])



  return (
  <>
  
   
 
   
       
        <div className='row'>
          <div className='col-md-12'>
 
            <div className="table-responsive">
              <h1 className='text-center mt-2'>Report</h1>


              {/* Display all the posts */}
              {posts}

              {/* Using React Paginate */}
             <div className='row mb-5'>
              <div className='col-md-4'>
                
              <CSVLink className="btn btn-success" data={reportdaat} headers={headers} filename={"Callreport.csv"} 
              target="_blank"  ><i className="fa fa-solid fa-download"></i> Download Report</CSVLink>
            </div>
              <div className='col-md-8'>
                <ReactPaginate
                  previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-sm">PRE</i>}
                  nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-sm">NEXT</i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  breakLinkClassName={"break-mef"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={offset - 1}

                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />

              </div>
              
             </div>
             
            </div>
           


          </div>


        </div>
      

      </>
    
  )
}

export default Calllog
