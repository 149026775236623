import React,{ useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';
import { BsTelephoneX } from "react-icons/bs";
import { MdOutlinePhoneForwarded } from 'react-icons/md';
import { FcCallback } from "react-icons/fc";


const Callback = () => {
    const [posts, setAllPosts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)
     //getAllPosts
     const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/AllCallBack?searchnumber=${numbersearch}&&pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }
    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [numbersearch, setNumbersearch] = useState('NA');

    const searchNumber =  (e) => {
      //  alert(campaigns)
    e.preventDefault()
    //setCampaigns(e)
     //alert(">>>>>>")
    // setCampaigns()
     getAllPosts()

    }


    const callBackFun = async (e) => {
       // alert(e)
         var formdata = new FormData()
      formdata.append('number', e)
    const response = await fetch(process.env.REACT_APP_IP + "/User/CallBack", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },
  
        headers: {
  
  
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
         body: formdata
        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      });
      const json = await response.json()
      // console.log(json);
      if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: json.msg,
          background: '#e9ecef',
          // title: 'Done Successfully ',
          //background: primary,
          showConfirmButton: false,
          timer: 2500
        })
      } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: json.msg,
          background: '#e9ecef',
          // title: 'Done Successfully ',
          //background: primary,
          showConfirmButton: false,
          timer: 2500
        })
      }
  


    }
    

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    }


    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card mt-1' >



                            <div className=''>
                                <div className='card-header text-black'>
                                    <h4 className='text-center'>Existing CallBack</h4>
                                </div>

                                <div className="table-responsive p-1">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>S.N</th>

                                                <th>CustomerNumber</th>

                                                <th>CallBack Time</th>
                                                <th>Remarks</th>
                                                

                                                


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>

                                                        <td>
                                                            <div className='row'>
                                                                <div className='col-md-3'>
                                                                             {page.custnumber}
                                                                </div>
                                                                <div className='col-md-9'>
                                                                        <button className={` "btn btn-warning  btn-mysm  "`}
                                                                                 onClick={() => callBackFun(page.custnumber)} 
                                                                                    >
                                                                        <FcCallback size="18px" className="me-2 text-white" />CALL</button>
                                                                    
                                                                </div>
                                                            </div>
                                                           
                                                        
                                                      


                                                        
                                                        </td>
                                                        <td>{page.callbacktime}</td>
                                                        <td>{page.remarks}</td>
                                                    
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage"
                                        onChange={onInputChange}
                                    />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }
  return (
    <>
    
    
    <div className='row'>

<div className='col-md-12'>

    {posts}
    <div className="row">
        <div className="col-md-3 col-12">

        </div>

        <div className="col-md-6 col-12">
            <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                breakLinkClassName={"break-mef"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                forcePage={offset - 1}

                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}

            />
        </div>

    </div>
    <div className="col-md-3 col-12">

<form 
 onSubmit={searchNumber} 
className='text-center'>
    <input type="text" className="form-control" id="searchnumber" name="searchnumber"
        placeholder="Number" 
        onChange={(e) => setNumbersearch(e.target.value)}
    // onChange={onInputChange}
        />
        <input type='submit' className='mt-1 btn btn-primary btn-mysm ' value="search" />

</form>

</div>

</div>

</div>

    
    
    
    
    </>
  )
}

export default Callback