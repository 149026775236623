import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
//import DatePicker from 'react-date-picker';
import axios from 'axios'


const AssignDid = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  })

  useEffect(() => {
    document.title = "Assign Did"
 }, []);



  const submiteditform = async (e) => {
    e.preventDefault();
    console.log(">>>>>channel>>" + channel)
    var formdata = new FormData()
    formdata.append('id', echannelid)
    formdata.append('did', channel)
    formdata.append('status', estatus)
    // formdata.append('name', eusername)
    formdata.append('userid', euserid)
    const response = await fetch(process.env.REACT_APP_IP + "/Admin/UpdateDid", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        // 'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });

    const json = await response.json()
    console.log(json);
    ref.current.click();
    if(json.type==="OK")
    {
       // props.showAlert(json.msg, "success");
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }else{
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: json.msg,
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }

    getAllPosts()

  }

  const [username, setUsername] = useState("ALL");

  const optionforusername = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/Admin/RestAllAdmindrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('usernameiddropdown');
    elelist.innerHTML = ""+('<option value="ALL">ALL</option>');

    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['username'] + '">' + json[i]['id'] + ' | ' + json[i]['username'] + '</option>';
    }


  console.log(">>>>>>>>"+adduserid)


  }

  useEffect(() => {
    optionforusername()
  }, [])
  
  const [channel, setchannel] = useState()

  const [echannelid, setEchannelid] = useState()
  const [eactive, setEactive] = useState()
  const [estatus, setEstatus] = useState()
  
  const [adduserid, setadduserid] = useState()
  const [euserid, seteuserid] = useState()
  const [eusername, seteusername] = useState()

  const ref = useRef(null)
  const addchannelref = useRef(null)


  const addChannel = async (e) => {

    addchannelref.current.click();
    const respp = await fetch(process.env.REACT_APP_IP+"/Admin/RestAllAdmindrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const jsona = await respp.json()

    var elelist2 = document.getElementById('usernameidaddmodel');

    //alert(">>>>>>>>>>>>"+json.userid);
    //  elelist2 = elelist2.empty()
    elelist2.innerHTML = ""+('<option value="">NA</option>');
    // elelist2.innerHTML = elelist2.innerHTML +
    //   ('<option  value="' + json.userid + '">' + json.userid + ' | ' + json.name + '</option>');

    //var newop;
    for (var i = 0; i < jsona.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist2.innerHTML = elelist2.innerHTML +
        ('<option  value="' + jsona[i]['id'] + '">' + jsona[i]['id'] + ' | ' + jsona[i]['username'] + '</option>');
    }

    console.log(eusername)
  }

  


  const submitaddChannel = async (e) => {
    e.preventDefault()
   console.log(">>>>>>>>>"+adduserid)
    addchannelref.current.click();
    var formdata = new FormData()
    formdata.append('userid', adduserid)
    formdata.append('channel', channel)
   
    const response = await fetch(process.env.REACT_APP_IP+"/Admin/AddDid", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
          //  'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata
        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if(json.type==="OK")
    {
       // props.showAlert(json.msg, "success");
       Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }else{
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: json.msg,
            background: '#e9ecef',
           // title: 'Done Successfully ',
           //background: primary,
            showConfirmButton: false,
            timer: 2500
          })
        }

    getAllPosts()



  }
  const updateChannel = async (e) => {

    ref.current.click();
    //alert(">>>>>>>>>>"+e);
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/Admin/getDidById", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        //'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata

      // body: JSON.stringify({
      //   "id": e

      // })

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    // console.log(json.campname);
    // console.log(json.campaign_mode);
    setEchannelid("")
    setEchannelid(json.id)
    //setEactive("")
    //setEactive(json.portactive)
    setEstatus("")
    setEstatus(json.assignstatus)

    setchannel('')
    setchannel(json.did)
    seteuserid("")
    seteuserid(json.userid)

    seteusername("")
    seteusername(json.username)

    const respp = await fetch(process.env.REACT_APP_IP+"/Admin/RestAllAdmindrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const jsona = await respp.json()

    var elelistedit = document.getElementById('usernameiddropdownl');

    //alert(">>>>>>>>>>>>"+json.userid);
    //  elelist2 = elelist2.empty()
    elelistedit.innerHTML = "";
    elelistedit.innerHTML = elelistedit.innerHTML +
       ('<option  value="' + json.userid + '">' + json.userid + ' | ' + json.username + '</option>');

    //var newop;
    for (var i = 0; i < jsona.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelistedit.innerHTML = elelistedit.innerHTML +
        ('<option  value="' + jsona[i]['id'] + '">' + jsona[i]['id'] + ' | ' + jsona[i]['username'] + '</option>');
    }

    console.log(eusername)
    // setEcampaignmode(json.campaign_mode)
    // setEcampaignid(json.id)
    // setestarttime(json.start_time)
    // setEendtime(json.end_time)
    // setEeselectaudio(json.audioid | json.audio_name)
    // setEeselectlist(json.listname)
    // setmaxtry(json.maxtry)

    //getAllPosts()
    //setIsToggled(!isToggled);




  }


  const filterreportsubmit = async (event) => {
    event.preventDefault();

    console.log(">>>>username>>>>>>>" + username);


    getAllPosts()




  }

  //const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
        <div className='mybgcolor'>
          <div className="table-responsive">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>ID</th>

                  <th>Username</th>
                  <th>Did</th>
                  <th>Status</th>
                  
                  <th>update</th>


                </tr>
              </thead>
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id}>
                      <td>{page.id}</td>
                      <td>{page.userid + " | " + page.username}</td>
                      <td>{page.did}</td>
                      <td>{page.assignstatus}</td>
                      
                      {/* <td>{page.portactive === 1 ? "DeActive" : "Active"}</td>
                      <td>{page.portstatus === 0 ? "FREE" : "BUSY"}</td> */}
                      <td> <button className=" mx-2 btn btn-primary" onClick={() => updateChannel(page.id)}>
                        <i className="fa fa-edit" ></i>
                      </button>
                      </td>


                    </tr>
                  );
                })}
              </tbody>
            </table>

            Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-3 mt-3" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
        </div>
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/Admin/DidSettingAction?pageNumber=${offset - 1}&&
    size=5&&username=${username}`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

  }
  useEffect(() => {
    getAllPosts()
  }, [offset])


  return (
    <>
     
     

      <div className='row p-1'>


            {/* model for add did */}

            <button ref={addchannelref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Did</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submitaddChannel}>
                      <div className="mb-2">
                        <label htmlFor="Channel" className="form-label">Did :</label>
                        <input type="text" className="form-control" id="channel" name="channel" value={channel}
                          onChange={(e) => setchannel(e.target.value)} required={true}  />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="user" className="form-label">User Name</label>
                        <select id="usernameidaddmodel" className="form-select" onChange={(e) => setadduserid(e.target.value)} required>
                          {/* <option value={euserid}>{euserid} | {eusername}</option>
                                        <option value=""></option> */}


                        </select>
                      </div>

                     
                      <div className="d-grid gap-2">
                        <input className="btn btn-primary mt-3" type="submit" value="Assign" />
                      </div>

                    </form>
                  </div>

                </div>
              </div>
            </div>









            {/* modal for update */}
            
            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Did</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submiteditform}>
                      <div className="mb-2">

                        <input type="text" className="form-control" id="echannelid" name="echannelid" value={echannelid}
                          onChange={(e) => setEchannelid(e.target.value)} required={true} readOnly={true} />
                      </div>

                      <div className="mb-2">
                        <label htmlFor="user" className="form-label">User Name</label>
                        <select id="usernameiddropdownl" className="form-select" name="euserid" onChange={(e) => seteuserid(e.target.value)} required>
                          {/* <option value={euserid}>{euserid} | {eusername}</option>
                                        <option value=""></option> */}
                                        


                        </select>
                      </div>

                      <div className="mb-2">

                        <label className="form-label">Did</label>
                        <input type="text" className="form-control" id="channel"  value={channel}
                          onChange={(e) => setchannel(e.target.value)} required={true} readOnly={true}  />


                      </div>

                      <div className="mb-2">
                        <label className="form-label">Status</label>
                        <select id="estatus" className="form-select" name="estatus"

                          onChange={(e) => setEstatus(e.target.value)} required>
                          <option value={estatus}>{estatus}</option>
                          <option value="FREE">FREE</option>
                          <option value="Assign">Assign</option>

                        </select>
                      </div>




                      <div className="d-grid gap-2">
                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                      </div>

                    </form>
                  </div>

                </div>
              </div>
            </div>





            <div className='col-md-3'>
              <div className='card p-2 mt-2'>
                <form onSubmit={filterreportsubmit} >
                                  
                            <h4 className='card-header text-center'>Did Setting</h4>
                  

                  <div className=" mb-2 mt-3">
                    <label htmlFor="user" className="form-label">User Name</label>
                    <select id="usernameiddropdown" className="form-select" name="username" onChange={(e) => setUsername(e.target.value)} required>
                      <option value="ALL">-- Select User --</option>
                      <option value="ALL">ALL</option>

                    </select>

                  </div>


                  <div className="d-grid gap-2 mb-5 mt-5">
                    <input className="btn btn-primary " type="submit" value="Submit" />
                  </div>
                </form>

              </div>









            </div>

            <div className='col-md-9'>
              <div className="table-responsive ">
                <div className='row '>
                    <div className='col-md-12 card-header text-center'>
                            {/* <h1>Did Setting</h1>
                    </div>
                    <div className='col-md-6 card-header text-center'> */}
                    {localStorage.getItem('roles') === 'ROLE_SUPERADMIN'?                    
                            <button className='btn btn-primary btn-sm float-start' onClick={addChannel}>Add Did</button>:''}
                            
                            <div style={{fontSize:"20px"}}>Existing Did Setting</div>
                    </div>
                </div>
                
                {/* Display all the posts */}
                {posts}

                {/* Using React Paginate */}

              </div>


              <div className='row mb-5'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}

                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />

                </div>
              </div>


            </div>


         

</div>


    </>
  )
}

export default AssignDid
